<template>
    <div class="box">
		<div>
			<h4 class="m-0">{{ $t('mouvement.lieu_stationnement') }}</h4>

            <div class="row align-items-center">
                <div v-if="tiers" class="col mt-2">
                    <h5 class="m-0">{{ tiers.tiers_rs }} <HasGroomyIcon :tiers_id="tiers.tiers_id" /></h5>
                    <i>{{ tiers.tiers_address1}}, {{ tiers.tiers_postalcode }} {{ tiers.tiers_town | uppercase }} {{ tiers.pays }}</i>
                </div>
                <div v-else class="col">
                    {{ $t("mouvement.choisir_tiers") }}
                </div>

                <div v-if="!horseFicheReadOnly" class="col-auto">
					<button class="btn btn-link" v-on:click.stop.prevent="changeLieuStationnement()">
						<span class="d-md-inline">{{ $t('lieu.changer_residence_principale') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
					</button>

					<button v-if="tiers" class="btn btn-link" v-on:click.stop.prevent="deleteLieuStationnement()">
						<span class="d-md-inline">{{ $t('lieu.delete_lieu_stationnement') }}</span> <font-awesome-icon class="ml-2" :icon="['fal', 'long-arrow-right']" />
					</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script type="text/javascript">
    import { EventBus } from 'EventBus';
    import Horse from "@/mixins/Horse.js";	
	import Shutter from '@/mixins/Shutter.js'
    import ShutterMvt from '@/mixins/shutters-manager/Mouvements.js'

	export default {
		name: "HorseLieuStationnement",
		mixins: [Horse, Shutter, ShutterMvt],
        props: [
            'horse_id',
        ],
		data () {
			return {
                tiers: null,
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            init_component() {
                this.getLieuStationnement()
            },

            async getLieuStationnement() {
				this.tiers = await this.getHorseLieuStationnement(this.horse_id)
            },

            changeLieuStationnement() {
				this.openChangeLieuStationnement(this.horse_id)
            },

			async deleteLieuStationnement() {
                await this.assignLieuStationnement(this.horse_id, null)
                this.getLieuStationnement()
			},

            async lieuStationnementSubmit(tiers) {
            	await this.assignLieuStationnement(this.horse_id, tiers.tiers_id)
            	this.getLieuStationnement()
            }
		},
        computed: {
            horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            }
        },
        components: {
            HasGroomyIcon : () => import('@/components/Utils/HasGroomyIcon')
        }
	}
</script>
